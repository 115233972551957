import Button from 'components/base/Button'
import FormItem from 'components/base/FormItem'
import Input from 'components/base/Input'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Updater } from 'use-immer'
import { LoginFormData, LoginProcessState } from './lib/types'
import PasswordInput from 'components/base/PasswordInput'
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { useIntl } from 'lib/intl-utils'
import Link from 'components/base/Link'
import { match } from 'ts-pattern'
import { useRouter } from 'next/router'

type Props = {
  processState: LoginProcessState
  setProcessState: Updater<LoginProcessState>
}

const LoginForm: FC<Props> = ({ processState, setProcessState }) => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const [generalError, setGeneralError] = useState<string | null>(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginFormData>({ mode: 'onSubmit' })
  const router = useRouter()

  const onUserDataSubmit = useCallback(async (data: LoginFormData) => {
    window.scrollTo({ top: 0 })

    const response = await ctx.logIn(data)

    match(response)
      .with({ status: 'AuthSuccess' }, () => {
        router.push('/products/list/')
      })
      .with({ status: 'AuthNeedTwoFactor' }, (response) => {
        setProcessState((s) => {
          s.stage = 'waitingForCode'
          s.data = data
          s.codeType = response.type
          s.phone = response.phone
        })
      })
      .with({ status: 'InvalidCredentials' }, () => {
        setGeneralError(t`login.invalid_credentials`)
      })
      .with({ status: 'UserBanned' }, () => {
        setGeneralError(t`login.user_banned`)
      })
      .with({ status: 'AttemptsLimitReached' }, (response) => {
        setGeneralError(
          t`login.attempts_limit_reached` +
            ` ${response.seconds} ` +
            t('second_accusative', { count: response.seconds })
        )
      })
      .exhaustive()
  }, [])

  useEffect(() => {
    if (!processState.data) return
    reset(processState.data)
  }, [processState.data])

  return (
    <>
      <form
        onSubmit={handleSubmit(onUserDataSubmit)}
        className="grid grid-cols-1 gap-x-6 gap-y-6 mt-16 sm:grid-cols-2"
      >
        <FormItem label="Email" error={errors.username?.message} className="col-span-full">
          <Input
            {...register('username', {
              required: t`required_field`,
            })}
            hasError={Boolean(errors.username?.message)}
            type="email"
            autoComplete="email"
          />
        </FormItem>
        <FormItem label={t`password`} error={errors.password?.message} className="col-span-full">
          <PasswordInput
            {...register('password', {
              required: t`required_field`,
            })}
            hasError={Boolean(errors.password?.message)}
            autoComplete="password"
          />
        </FormItem>
        <div className="col-span-full space-y-2">
          {generalError && <p className="text-red-600">{generalError}</p>}
          <Button type="submit" theme="primary" className="justify-center space-x-1 w-full">
            <span>{t`log_in`} </span>
            <ArrowNarrowRightIcon className="w-4 h-4" />
          </Button>
          <p className="text-xs text-center text-gray-500">
            {ctx.theme.hostType === 'sellmonitor' && (
              <>
                Входя в аккаунт, вы соглашаетесь с{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/public-offer/"
                  className="text-primary-600"
                >
                  публичной офертой
                </a>{' '}
                и{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doc/sellmonitor/ru/privacy_policy.pdf"
                  className="text-primary-600"
                >
                  политикой конфиденциальности
                </a>
              </>
            )}
            {ctx.theme.hostType === 'sellscreen' && (
              <>
                By logging in, you agree to the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doc/sellscreen/en/terms.pdf"
                  className="text-primary-600"
                >
                  Terms of Service
                </a>{' '}
                and the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doc/sellscreen/en/privacy_policy.pdf"
                  className="text-primary-600"
                >
                  Privacy Policy
                </a>
              </>
            )}
          </p>
        </div>
      </form>
      <div className="mt-8 text-center">
        <Link
          href="/reset-password/"
          className="text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          {t`login.forgot_password`}?
        </Link>
      </div>
      {['sellmonitor', 'sellscreen', 'uzum'].includes(ctx.theme.hostType) && (
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2">
          <div className="flex items-center space-x-4 text-sm text-gray-500">
            <p className="w-max">{t`login.not_registered`}?</p>
            <Button theme="white" as={Link} href="/signup/">
              {t`login.sign_up`}
            </Button>
          </div>
        </div>
      )}
      {['sellematics'].includes(ctx.theme.hostType) && (
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2">
          <div className="flex items-center space-x-4 text-sm text-gray-500">
            <p className="w-max">{t`login.not_registered_sellematics`}?</p>
            <Button theme="white" as="a" className="w-max" href="/#access">
              {t`login.contact_us`}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default LoginForm
