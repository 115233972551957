import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { FC } from 'lib/component-utils'
import { forwardRef, useState } from 'react'
import Input from './Input'

const PasswordInput: FC<{}, typeof Input> = ({ ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const Icon = showPassword ? EyeOffIcon : EyeIcon
  const eyeButton = (
    <button
      type="button"
      className="flex items-center justify-center h-full pl-2 pr-3 -ml-2 -mr-3 group shrink-0 focus:outline-none"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setShowPassword((v) => !v)
      }}
    >
      <Icon className="w-5 h-5 text-gray-400 duration-200 rounded-full shrink-0 group-hover:text-gray-600 group-hover:transition group-focus:text-primary-600" />
    </button>
  )

  return (
    <Input ref={ref} {...rest} type={showPassword ? 'text' : 'password'} placeRight={eyeButton} />
  )
}

export default forwardRef(PasswordInput as any) as typeof PasswordInput
